.sales-records {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .sales-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .sales-table th,
  .sales-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .sales-table th {
    background-color: #f2f2f2;
  }
  
  .loading,
  .error {
    margin-top: 20px;
    padding: 10px;
    background-color: #f8d7da; /* Red background for error */
    color: #721c24; /* Dark red text for error */
    border: 1px solid #f5c6cb; /* Light red border for error */
  }
  
  .error {
    background-color: #d4edda; /* Green background for success */
    color: #155724; /* Dark green text for success */
    border: 1px solid #c3e6cb; /* Light green border for success */
  }
  
  @media screen and (max-width: 600px) {
    .sales-table {
      font-size: 12px; /* Reduce font size for small screens */
    }
  }
  .summary {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .summary h2 {
    margin-bottom: 10px;
  }
  
  .filter-options {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .filter-options button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .filter-options button:hover {
    background-color: #45a049;
  }
  
  