.modal-overlay2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content2 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .inventory-form2 {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .inventory-form2 input {
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .pin-submit-btn2 {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }

  .pin-submit-btn {
    margin-top: 20px;
  }
  