body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.product-list, .cart-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.product, .cart-item {
  border: 1px solid #ccc;
  padding: 16px;
  margin: 8px;
  width: 200px;
  text-align: center;
}

h1 {
  text-align: center;
  margin-top: 20px;
}

a {
  text-decoration: none;
  color: inherit;
}

.inventory-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.inventory-form input {
  margin: 5px;
  padding: 10px;
  width: 200px;
}

.inventory-form button {
  padding: 10px 20px;
  margin-top: 10px;
}
