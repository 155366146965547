/* styles.css */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .login-container h1 {
    margin-bottom: 20px;
  }
  
  .login-container input {
    margin-bottom: 10px;
    padding: 10px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .login-container .error {
    color: red;
    margin-bottom: 10px;
  }
/* styles.css */
/* styles.css */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* styles.css */
.admin-dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.admin-content {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  border-radius: 8px;
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: calc(100vh - 160px); /* Adjust height according to header and footer */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #ff1a1a;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-icon {
  margin-right: 10px;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  background: #f1f1f1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 200px;
}

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delete-button {
  background-color: #ff4d4d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

.edit-button {
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.edit-button:hover {
  background-color: #45a049;
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
}

.quantity-input {
  width: 50px;
  text-align: center;
  margin: 0 5px;
}
.delete-button {
  background-color: #ff4d4d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

.edit-button {
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.edit-button:hover {
  background-color: #45a049;
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
}

.quantity-input {
  width: 50px;
  text-align: center;
  margin: 0 5px;
}


