/* General styling */
body {
  font-family: Arial, sans-serif;
}

/* Header styling */
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
}

header .logo {
  height: 50px;
}

header h1 {
  flex-grow: 1;
  text-align: center;
}

header nav {
  display: flex;
  align-items: center;
  gap: 15px;
}

header nav a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
}

header nav a:hover {
  color: #e0e0e0;
}

header button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.navbar-toggle {
  display: none;
}

@media (max-width: 768px) {
  .navbar-toggle {
    display: block;
    cursor: pointer;
  }

  .nav {
    display: none;
    flex-direction: column;
    gap: 10px;
  }

  .nav.open {
    display: flex;
  }
}

/* Footer styling */
footer {
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 10px 20px;
}

/* Search bar styling */
.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 5px;
}

.search-bar input {
  border: none;
  padding: 10px;
  flex: 1;
  border-radius: 5px;
}

.search-icon {
  color: #888;
  margin-right: 10px;
}

/* Button styling */
button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Product grid styling */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.product-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Quantity control styling */
.quantity-control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-btn {
  background-color: #c5cbc5;
  color: rgb(217, 32, 32) !important;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.quantity-btn:hover {
  background-color: #45a049;
}

/* Added text styling */
.added-text {
  color: green;
  font-weight: bold;
}

/* Cart grid styling */
.cart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}

.cart-item {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
}

.remove-btn {
  padding: 8px;
  background-color: #ff0000;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 8px;
}

/* Admin content styling */
.admin-content {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  border-radius: 8px;
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: calc(100vh - 160px); /* Adjust height according to header and footer */
}

/* Field styling */
.field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.field label {
  width: 100px;
  font-weight: bold;
}

.field span {
  margin-right: 10px;
}

/* Edit button styling */
.edit-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #0056b3;
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 300px;
}

.modal-close {
  position: absolute;
  top: 1px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}
/* styles.css */

.product-list-page {
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f8f9fa;
}

.logo {
  height: 50px;
}

nav {
  display: flex;
  align-items: center;
}

nav a, nav button {
  margin: 0 1rem;
  text-decoration: none;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
}

nav button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.main-content {
  padding: 2rem;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.search-icon {
  margin-right: 0.5rem;
}

.search-bar input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.product-card {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.product-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.added-text {
  color: green;
  font-weight: bold;
}

.cart-icon {
  cursor: pointer;
  color: #007bff;
}

.quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-input {
  width: 90px !important;
  text-align: center;
  margin: 0 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}


