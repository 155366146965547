.receipt {
  max-width: 300px; /* Adjust as necessary for your mini printer */
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 12px; /* Adjust font size for readability */
}

.receipt h1 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px; /* Larger font size for headings */
  color: #333;
}

.receipt p {
  border-top: 1px dotted #000;
  text-align: center;
  margin: 5px 0;
  size: 40px
}

.receipt-table {
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
}

.receipt-table th,
.receipt-table td {
  border: 1px dotted #000;
  padding: 5px;
  text-align: left;
}

.receipt-table th {
  background-color: #f5f5f5;
}

.total-price {
  text-align: right;
  margin-top: 10px;
  font-size: 14px; /* Larger font size for total */
}

.receipt button {
  display: block;
  margin: 10px auto;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px; /* Adjust button font size */
}

.receipt button:hover {
  background-color: #0056b3;
}

.receipt-footer {
  text-align: center;
  margin-top: 10px;
  font-size: 10px; /* Smaller font size for footer */
  color: #555;
  border-top: 1px dotted #000;
  padding-top: 5px;
}
.thankyou {
  size: 70px,
}