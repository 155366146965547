/* Header.css */
.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #343a40;
    color: white;
    padding: 15px 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .header-logo {
    display: flex;
    align-items: center;
  }
  
  .header-logo img.logo {
    height: 40px;
    margin-right: 10px;
  }
  
  .header-logo h1 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .menu-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .admin-header nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  .admin-header nav ul li a,
  .admin-header nav ul li button {
    color: white;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1em;
    padding: 8px 16px;
    transition: background-color 0.3s;
  }
  
  .admin-header nav ul li a:hover,
  .admin-header nav ul li button:hover {
    background-color: #495057;
    border-radius: 4px;
  }
  
  .admin-header nav ul li button {
    background-color: #dc3545;
    border-radius: 4px;
  }
  
  .admin-header nav ul li button:hover {
    background-color: #c82333;
  }
  
  @media (max-width: 768px) {
    .menu-toggle {
      display: block;
    }
  
    .admin-header nav {
      display: none;
    }
  
    .admin-header.nav-open nav {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #343a40;
    }
  
    .admin-header nav ul {
      flex-direction: column;
      gap: 0;
    }
  
    .admin-header nav ul li {
      text-align: center;
      padding: 10px 0;
    }
  
    .admin-header nav ul li a,
    .admin-header nav ul li button {
      display: block;
    }
  }
/* Footer.css */
.admin-footer {
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 15px 0;
    margin-top: 40%;
    position: fixed;
    width: 100%;
    bottom: 0;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    font-size: 0.9em;
  }
  
  @media (max-width: 768px) {
    .admin-footer {
      font-size: 0.8em;
      padding: 10px 0;
    }
  }
    